<template>
  <v-container class="container--fluid grid-list-md">
    <v-progress-circular
        :width="10"
        :size="80"
        indeterminate
        v-if="circular"
    ></v-progress-circular>
    <div v-if="!circular">
      <h2>{{ $t('fields.result_search') }}</h2>
      <div v-if="view">
        <div class="d-flex justify-end mb-3">
          <v-btn
              small
              @click="changeViewExpand()"
          >
            <v-icon small left>mdi-eye-outline</v-icon>
            <span v-if="activeRowsEx.length === productsList.length">{{ $t('btns.hide_details') }}</span>
            <span v-else>{{ $t('btns.show_details') }}</span>
          </v-btn>
        </div>
        <div
            class="wrap-progress"
        >
          <v-progress-linear
              indeterminate
              height="2"
              light
              absolute
              :active="getLoading"
          ></v-progress-linear>
        </div>
        <custom-table
            :headers="headers"
            :items="products"
            selectable="multiple"
            :expand-rows="expandRows"
            @toggleExpand="toggleEx"
            :active-expand-rows="activeRowsEx"
            height="calc(100vh - 215px)"
            @send="open"
            @eSort="eSort"
        />
        <div class="text-center">
          <v-pagination
              class="mt-2"
              v-model="current_page"
              :length="all_page"
              :total-visible="7"
          ></v-pagination>
        </div>
      </div>
      <div v-else class="text-center mt-8">
        <h3>Ничего не найдено</h3>
        <v-btn
            class="mt-5"
            color="primary"
            small
            outlined
            @click="$router.go(-1)"
        >Назад
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ResultSearch',
  data() {
    return {
      productsList: [],
      activeRowsEx: [],
      searchProduct: null,
      current_page: 1,
      all_page: null,
      size_per_page: 30,
      headers: [
        {
          text: this.$t('fields.category'),
          value: "categoryName",
          width: 230,
          sortable: true,
          edit: false,
          search: true,
        },
        {
          text: this.$t('fields.Product_Code_SKU'),
          value: "code",
          width: 130,
          sortable: true,
          edit: false,
          search: true,
        },
        {
          text: this.$t('fields.name2'),
          value: "name",
          width: 500,
          sortable: true,
          edit: false,
          search: true,
          template: 'name',
        },
        {
          text: this.$t('fields.vendor'),
          value: "vendor",
          width: 200,
          sortable: true,
          edit: false,
          search: true,
        },
      ],
      expandRows: [
        {
          value: "description",
          template: "description",
          width: 400,
          title: this.$t('fields.description'),
        },
        {
          value: "name",
          template: "params",
          width: 200,
          title: this.$t('fields.parameters_seller'),
        },
        {
          value: "name",
          template: "images",
          width: 500,
          title: this.$t('fields.photo'),
        },
      ],
      params: {},
      view: false,
      circular: false
    }
  },
  mounted() {
    this.getApiProducts()
  },
  computed: {
    ...mapGetters(["getResultSearch", "getLoading", "getValueSearch"]),
    products() {
      let arr = []
      for (let product of this.productsList) {
        let obj = {
          ...product.translations[0],
          ...product,
        }
        arr.push(obj)
      }
      return arr
    }
  },
  watch: {
    current_page() {
      this.getApiProducts()
    },
    getValueSearch() {
      this.circular = true
      this.getApiProducts()
    }
  },
  methods: {
    ...mapActions(["searchProducts"]),
    open(e) {
      if (e.id)
        this.$router.push(`/product/${e.id}`)
    },
    getApiProducts() {
      this.searchProducts(this.current_page).then(function (success) {
        let response = this.getResultSearch
        this.productsList = response.items
        this.all_page = response._meta.pageCount
        if (response._meta.totalCount > 0) {
          this.view = true
        } else this.view = false
        this.circular = false
      }.bind(this))
    },
    eSort(sort) {
      this.params.sort = sort.dir === "desc" ? sort.by : "-" + sort.by
      // 'add': false,
      this.getApiProducts(this.params)
    },
    toggleEx(e) {
      this.activeRowsEx = e
    },
    changeViewExpand() {
      let arr = []
      if (this.activeRowsEx.length === this.products.length) {
        this.activeRowsEx = []
      } else if (this.activeRowsEx.length < this.products.length) {
        this.products.forEach(prod => arr.push(prod.id))
        this.activeRowsEx = arr
      }
    },
  }
};
</script>

<style>

.search-input {
  max-width: 250px !important;
}

.v-data-table-header {
  background: #f1f3f6;
}

</style>
